.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #2f5497;
  border-color: #2f5497;

  &:hover {
    background-color: #2f5497;
    border-color: #2f5497;
  }
}
