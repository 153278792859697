.text-box {
  font-family: 'Rubik', sans-serif;
  background: #ffffff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding: 20px 32px;
  margin-bottom: 16px;

  h1 {
    font-size: 1.25rem;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  h2 {
    font-size: 1.2rem;
    margin-bottom: 12px;
    margin-top: 14px;
  }
  h3 {
    font-size: 1.10rem;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  h4 {
    font-size: 1.05rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1rem;
  }

  p,
  li {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 10px;

    strong {
      //margin-left: 20px;
    }
  }
}

.text-box {
  position: relative;
  overflow: hidden;
}

.text-box:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -15px;
  right: -15px;
  box-shadow: inset white 0 -20px 20px;
  pointer-events: none;
}
