@tailwind base;

@layer base {
  #root {
    height: 100%;
  }

  h1 {
    @apply text-[1.75rem];
    @apply font-semibold;
  }

  h2 {
    @apply text-[1.313rem];
    @apply font-semibold;
  }

  h3 {
    @apply text-xl;
    @apply font-semibold;
  }

  h4 {
    @apply text-lg;
    @apply font-semibold;
  }

  h5 {
    @apply text-base;
    @apply font-semibold;
  }

  h6 {
    @apply text-sm;
    @apply font-semibold;
  }

  svg,
  img {
    @apply inline;
    @apply align-baseline;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
